.sliderWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tabs{
    background-color: black;
    height: 100%;
}
.caption {
    text-align: center;
    font-size: 13px;
    color : white;
}

.dateSelector {
    float: left;
}
.MuiButton-contained{
    background-color: #ffffff !important;
}
.MuiButton-root{
    font-family: "Space Quest" !important;
    font-weight: lighter !important;
    font-size: 14pt !important;
    color: white !important;
    height: 30px !important;
    width: 65px !important;
    text-transform: none !important;
    vertical-align: center !important;
    border: 1px solid white !important;
    background: linear-gradient(180deg, rgb(56, 73, 184) 0%, rgb(18, 3, 92) 100%) !important;
}
.dateDisplay {
    font-family: "Space Quest";
    text-align: center;
    font-size: 18pt;
    width: 80px;
    margin-right: 7px;
    color: white;
}
.react-tabs__tab-panel {
    margin-left: 0 !important;
}
.react-tabs__tab-panel--selected {
    margin-left: 0 !important;
}
.react-tabs__tab-list {
    margin-bottom: 0 !important;
}
.slider {
    float: center;
    width: 45%;
    margin-right: 20px;
}

.rangeslider__fill {
    height: 100%;
    background: linear-gradient(360deg, rgb(9, 78, 54) 0%, rgb(17, 203, 189) 80%) !important;
    border-radius: 10px;
    top: 0;
}

.button {
    vertical-align: middle;
    width: 80px;
    height: 35px;
    margin-left: 60px;
    font-size: 15pt;
}
body {
    background-color: black;
}

@media screen and (max-width: 960px) {
    .sliderWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        margin-bottom: 20px;
    }
    body {
        background-color: black;
    }
    .dateDisplay {
        font-family: Roboto;
        text-align: center;
        font-size: 18pt;
        width: 80px;
        margin-right: 15px;
        color: white;
        font-weight: bold !important;
    }
    .slider {
        float: left;
        width: 55%;
    }
    .caption {
        text-align: center;
        color : white;
        font-size: 10pt;
    }
    .dateDisplay {
        font-family: Roboto;
        text-align: left;
        font-size: 18pt;
        width: 60px;
        margin-right: 3px;
        color: white;
    }
    .button {
        display: flex;
        justify-content: center;
        width: 50px;
        height: 30px;
        font-size: 12pt;
        font-family: -apple-system;
        color: white;
        background-color: black;
        border-color: white;
        border-width: 3px;
        border-radius: 20px;
    }
    .MuiButton-root{
        font-family: "Space Quest" !important;
        font-weight: lighter !important;
        font-size: 12pt !important;
        color: white !important;
        height: 25px !important;
        width: 50px !important;
        text-transform: none !important;
        vertical-align: center !important;
        border: 1px solid white !important;
        background: linear-gradient(180deg, rgb(56, 73, 184) 0%, rgb(18, 3, 92) 100%) !important;
    }
}