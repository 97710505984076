.tableCell {
    color:  white !important;
    font-size: 12pt !important;
    padding-top: 10px !important;
}
.tableHead {
    background: linear-gradient(360deg, rgb(186, 186, 186) 0%, rgb(255, 255, 255) 60%);
    color: white !important;
}
.MuiTableCell-head {
    font-size: 10pt !important;
    font-weight: bold !important;
    color: #000000 !important;
    padding-bottom: 3px !important;
}

.MuiTableCell-body {
    font-size: 10pt !important;
}
.MuiTableCell-root {
    color: white;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    border-color: #636262 !important;
}
@media screen and (max-width: 960px) {
    .MuiTableCell-root {
        color: white;
        padding-bottom: 8px !important;
        padding-top: 8px !important;
        border-color: #636262 !important;
    }

    .MuiTableCell-body {
        font-size: 10pt !important;
    }
}